import { request, getClientId } from '../utils/request';
import { isObject, isFunction, isWechatWeapp } from '../utils/lang';
import { getLocalISOString } from '../utils/date';
import { uuid } from '../utils/string';
import { getPlatform } from '../utils/platform';
import { getUtmProperties } from '../utils/utm';
import DebounceBuffer from '../utils/DebounceBuffer';

/**
 * 基础事件
 */
class Event {
  /**
   * 创建 Event 实例
   */
  constructor() {
    const { _buffer } = Event;
    if (_buffer) {
      this._buffer = _buffer;
    } else {
      this._buffer = new DebounceBuffer(this.report.bind(this));
      Event._buffer = this._buffer;
    }
  }

  /**
   * 上报客户事件
   *
   * @private
   * @param {object[]} logs - 客户事件缓存数组
   * @returns {Promise<object>} 发送请求后的回调
   */
  report(logs) {
    return request.post('/v2/memberEventLogs', { clientId: getClientId(), logs });
  }

  /**
   * 记录客户事件
   *
   * @param {string} name - 事件名称，需与群脉环境和账号下面的事件名匹配
   * @param {object} properties - 事件属性，需与事件名称下的属性匹配，可以在客户行为记录中展示出来
   * @param {function} [cb] - 回调函数，在记录客户事件成功或者失败后被调用
   */
  log(name, properties, cb) {
    Event.validate(name, properties, cb);
    const { url, content } = getPlatform();
    let scene = null;
    if (isWechatWeapp) {
      scene = wx.getLaunchOptionsSync().scene;
    }
    const mergedProperties = { url, scene, pageContent: content, ...getUtmProperties(), ...properties };
    if (isObject(properties.extra)) {
      properties.extra = JSON.stringify(properties.extra);
    }
    const data = Event.getLog(name, mergedProperties);
    this._buffer.debounce(data, cb);
  }

  /**
   * 获取客户事件对象
   *
   * @private
   * @param {string} name - 事件名称
   * @param {object} properties - 事件属性
   * @returns {object} 客户事件对象
   */
  static getLog(name, properties) {
    return {
      name,
      properties: JSON.stringify(properties),
      id: uuid(),
      occurredAt: getLocalISOString(),
    };
  }

  /**
   * 验证参数类型
   *
   * @private
   * @param {string} name - 事件名称
   * @param {object} properties - 事件属性
   * @param {function} [cb] - 回调函数
   */
  static validate(name, properties, cb) {
    if (!name) {
      throw new Error('name is required');
    }
    if (properties && !isObject(properties)) {
      throw new Error('The properties parameter should be a key-value object');
    }
    if (cb && !isFunction(cb)) {
      throw new Error('The cb parameter should be a function');
    }
  }

  /**
   * 验证必填字段
   *
   * @private
   * @param {string} obj - 需要校验的对象
   * @param {object} fields - 需要校验的字段
   */
  static validateRequiredFields(obj, fields) {
    if (!isObject(obj) || !Array.isArray(fields)) {
      return;
    }
    fields.forEach((field) => {
      if (!obj[field]) {
        throw new Error(`${field} is required`);
      }
    });
  }
}

export default Event;
