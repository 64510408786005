import { isWechatWeapp } from './lang';

const WEAPP_ERROR_MSG_REGX = /(\S+)\n/; // 匹配第一行的错误类型
const WEAPP_FILE_REGX = /at.+\((.+)\)/; // 匹配所在文件

const onError = new Promise((resolve) => {
  if (isWechatWeapp) {
    if (wx.onError) {
      wx.onError((error) => {
        const message = error.match(WEAPP_ERROR_MSG_REGX)[1];
        const file = error.match(WEAPP_FILE_REGX)[1];
        resolve({ message, context: { file }, error: new Error(error) });
      });
    } else {
      resolve();
    }
  } else {
    window.onerror = (message, file, line, column, error) => {
      const context = { file, line, column };
      resolve({ message, context, error });
    };
  }
});

export {
  onError,
};
