import { request, envConfig, staffRequest } from './utils/request';
import { getLocalISOString } from './utils/date';
import { uuid } from './utils/string';
import DebounceBuffer from './utils/DebounceBuffer';
import Member from './Member';
import Staff from './Staff';
import wxWeapp from './WxWeapp';

/**
 * 微信小程序类
 */
class Weapp {
  /**
   * 创建 Weapp 实例
   */
  constructor() {
    this._buffer = new DebounceBuffer((reports) => request.post('/v2/weapp/formIdReports', { reports }));
  }

  /**
   * 上报微信小程序 formId，需要授权 {@link Member#signin}
   *
   * @deprecated 推荐使用{@link https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/subscribe-message.html|小程序订阅消息}
   *
   * @param {string} formId - 提交表单时的 formId
   */
  reportFormId(formId) {
    Weapp.validate(formId);
    const data = {
      formId,
      createdAt: getLocalISOString(),
    };
    this._buffer.debounce(data);
  }

  /**
   * 上传临时文件
   *
   * @param {string} openId - 用户的 openId，仅作唯一标识文件使用
   * @param {string} tempFilePath - 微信小程序临时文件路径
   * @param {object} [ossConfig] - oss 相关配置，为空时会自动获取
   * @param {boolean} [ossConfig.withStaffRequest=false] - 是否使用 StaffRequest 获取 OSS signature
   * @param {string} ossConfig.domain - oss 访问域名
   * @param {object} ossConfig.formData - oss 上传文件需携带的相关字段
   * @param {string} ossConfig.directory - oss 上传目录
   * @returns {Promise<object>}
   */
  async uploadTempFile(openId, tempFilePath, ossConfig = {}) {
    let checkedOssConfig = { ...ossConfig };
    if (!ossConfig.directory) {
      const rest = ossConfig.withStaffRequest ? staffRequest : request;
      checkedOssConfig = await rest.get('/v2/oss/signature');
    }

    const source = tempFilePath;
    const ext = /\.\w+$/.exec(source)[0];
    const file = `${checkedOssConfig.directory}/followers/${openId}/${uuid()}${ext}`;
    await wxWeapp.uploadFile({
      url: checkedOssConfig.domain,
      filePath: source,
      name: 'file',
      formData: {
        ...checkedOssConfig.formData,
        key: file,
      },
    });

    return {
      source,
      url: `${checkedOssConfig.domain}/${file}`,
    };
  }

  /**
   * 上传图片，参数参考微信小程序 chooseImage 定义，需要授权 {@link Member#signin}
   *
   * @param {object} options
   * @param {number} options.count - 最多可以选择的图片张数
   * @param {"original" | "compressed"} options.sizeType - 所选的图片的尺寸
   * @param {"album" | "camera"} options.sourceType - 选择图片的来源
   * @param {boolean} options.needImageInfo - 是否需要返回图片信息
   * @param {boolean} [options.ignoreLoading=false] - 是否展示 Loading
   * @param {boolean} [options.withStaffRequest=false] - 是否使用 StaffRequest 获取 OSS signature
   * @returns {Promise<Array<object>>}
   */
  async uploadImages(options) {
    if (!options.count) {
      throw new Error('count is required');
    }

    const { tempFilePaths } = await wxWeapp.chooseImage(options);
    try {
      const member = new Member();
      const staff = new Staff();
      const openId = member.getOpenId() || staff.getOpenId();
      const items = [];
      if (!options.ignoreLoading) {
        wx.showLoading({ mask: true, title: '上传中...' });
      }

      const rest = options.withStaffRequest ? staffRequest : request;
      const ossConfig = await rest.get('/v2/oss/signature');
      for (let index = 0; index < tempFilePaths.length; index += 1) {
        // eslint-disable-next-line
        const item = await this.uploadTempFile(openId, tempFilePaths[index], ossConfig, index);
        if (options.needImageInfo) {
          // eslint-disable-next-line
          const { type, height, width } = await wxWeapp.getImageInfo({ src: item.source });
          item.type = type;
          item.height = height;
          item.width = width;
        }
        items.push(item);
      }

      return items;
    } finally {
      if (!options.ignoreLoading) {
        wx.hideLoading();
      }
    }
  }

  /**
   * 通过微信小程序绑定手机号，需要授权 {@link Member#signin}
   *
   * @param {object} options
   * @param {string} options.code - [用户登录凭证](https://developers.weixin.qq.com/miniprogram/dev/api/wx.login.html)
   * @param {string} options.iv [加密算法的初始向量](https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/getPhoneNumber.html)
   * @param {string} options.encryptedData [包括敏感数据在内的完整用户信息的加密数据](https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/getPhoneNumber.html)
   * @param {boolean} options.allowMerge 是否允许自动 merge 手机号相同的 member。
   * @returns {object}
   */
  async bindPhone(options) {
    if (!options.code) {
      throw new Error('code is required');
    }

    if (!options.iv) {
      throw new Error('iv is required');
    }

    if (!options.encryptedData) {
      throw new Error('encryptedData is required');
    }

    const member = await request.put('/v2/member/bindPhoneFromMiniProgram', options);
    Member.instance.updateAs(member);
    return member;
  }

  /**
   * 微信小程序的 version 可以从 request referer 中获取，详情参考 [微信开发者文档](https://developers.weixin.qq.com/miniprogram/dev/framework/ability/network.html)
   *
   * @returns {string} 取值范围 undefined | devtools | 0 | N+
   * - 如果未 signin 则返回 undefined
   * - 如果使用开发工具，则返回 devtools
   * - 如果是开发版或者体验版，则返回 0
   * - 如果是正式版，则返回 N+，且随着每次发布自增
   */
  getVersion() {
    return envConfig.weappVersion;
  }

  /**
   * 更新用户授权信息
   *
   * @since 1.28.0
   * @param {object} [userProfile={}] - 用户信息
   * @param {string} userProfile.avatarUrl - 头像
   * @param {string} userProfile.nickName - 昵称
   * @param {string} userProfile.gender - 性别
   * @param {string} userProfile.country - 国家
   * @param {string} userProfile.province - 省
   * @param {string} userProfile.city - 市
   * @param {string} [userProfile.lang='zh_CN'] - 显示用户信息的语言，参考 [wx.getUserProfile](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserProfile.html)
   * @param {string} [userProfile.desc='获取用户信息'] - 声明获取用户个人信息后的用途，不超过30个字符，参考 [wx.getUserProfile](https://developers.weixin.qq.com/miniprogram/dev/api/open-api/user-info/wx.getUserProfile.html)
   */
  async authSocial(userProfile = {}) {
    if (!userProfile?.avatarUrl && !userProfile?.nickName) {
      const { userInfo } = await wxWeapp.getUserProfile({
        lang: userProfile.lang || 'zh_CN',
        desc: userProfile.desc || '获取用户信息',
      });

      // eslint-disable-next-line no-param-reassign
      userProfile = userInfo;
    }
    const member = await request.post('/v2/member/authSocial', this.convertUserProfileToInfo(userProfile));
    Member.instance.updateAs(member);
    Member.instance.updateSocial(member);
  }

  /**
   * 转换从 wx.getUserProfile() 获取的 userInfo 信息
   *
   * @private
   * @param {object} userProfile - 用户信息
   * @param {string} userProfile.avatarUrl - 头像
   * @param {string} userProfile.nickName - 昵称
   * @param {string} userProfile.gender - 性别
   * @param {string} userProfile.country - 国家
   * @param {string} userProfile.province - 省
   * @param {string} userProfile.city - 市
   * @returns {object}
   */
  convertUserProfileToInfo(userProfile) {
    return {
      avatar: userProfile.avatarUrl,
      nickname: userProfile.nickName,
      gender: ['unknown', 'male', 'female'][userProfile.gender],
      country: userProfile.country,
      province: userProfile.province,
      city: userProfile.city,
    };
  }

  /**
   * 验证参数类型
   *
   * @private
   * @param {string} formId - 提交表单时的 formId
   */
  static validate(formId) {
    if (!formId) {
      throw new Error('formId is required');
    }
  }
}

export default Weapp;
