import qs from 'qs';
import { isWechatWeapp } from './lang';
import sessionStorage from './sessionStorage';

const ACCESS_TOKEN_KEY = 'mai_consumer_access_token';

const getTokenFromUrl = () => {
  const { search, hash } = window.location;
  const searchQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const hashQuery = qs.parse(hash.replace(/^#[^?]*\?/, ''));
  const query = { ...searchQuery, ...hashQuery };
  if (query.accessToken) {
    sessionStorage.setItem(ACCESS_TOKEN_KEY, query.accessToken);
    const path = [{ url: search, query: searchQuery }, { url: hash, query: hashQuery }]
      .map((i) => {
        delete i.query.accessToken;
        const queryStr = qs.stringify(i.query);
        return i.url.replace(/\?.+/, `?${queryStr}`);
      }).join('');
    const newUrl = window.location.origin + window.location.pathname + path;
    window.history.replaceState(null, null, newUrl);
    return query.accessToken;
  }
  return '';
};

const getTokenFromStorage = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);

const getAccessToken = (options = {}) => {
  if (isWechatWeapp) {
    return options.accessToken;
  }
  return options.accessToken || getTokenFromUrl() || getTokenFromStorage();
};

export {
  getAccessToken,
};
