import { request, envConfig } from './utils/request';
import { isObject } from './utils/lang';
import { getLocalISOString } from './utils/date';
import { getPlatform } from './utils/platform';
import { onError } from './utils/error';
import DebounceBuffer from './utils/DebounceBuffer';

/**
 * 日志上报类
 */
class Log {
  /**
   * 创建 Log 实例
   */
  constructor() {
    this._buffer = new DebounceBuffer((logs) => request.post('/v2/appLogs', { logs }));
  }

  /**
   * 获取 Logger 实例
   *
   * @param {string} category - 日志类目，建议每个文件一个 logger 和 CATEGORY
   * @return {Object({warning: Function(message, context, error), error: Function(message, context, error)})}
   *
   * @example
   * const logger = mai.log.getLogger('demo');
   * logger.warning(message, context, error);
   * logger.error(message, context, error);
   */
  getLogger(category) {
    return {
      warning: (message, context, error) => {
        this.log(category, 'warning', message, context, error);
      },
      error: (message, context, error) => {
        this.log(category, 'error', message, context, error);
      },
    };
  }

  /**
   * 上报日志
   *
   * @private
   * @param {string} category - 日志类目
   * @param {string} level - 日志等级，可选值 warning、error
   * @param {string} message - 错误信息，日志的概括性内容
   * @param {object} [context={}] - 日志发生时的用户相关的场景信息，默认会上报浏览器信息或者微信小程序基础库信息、设备信息。
   * @param {string} [error] - 错误对象，一般是 try...catch 捕获到的 error 对象
   */
  log(category, level, message, context = {}, error) {
    Log.validate(category, level, message, context, error);
    const data = {
      level,
      message,
      backtrace: error ? error.stack : '',
      category,
      context: JSON.stringify({ ...context, platform: getPlatform() }),
      occurredAt: getLocalISOString(),
    };

    if (envConfig.options.appName) {
      data.category = `${envConfig.options.appName}/${data.category}`;
    }

    this._buffer.debounce(data);
  }

  /**
   * 捕获全局 js 脚本错误信息
   */
  catchGlobalErrors() {
    onError.then((data) => {
      if (!data) {
        return;
      }
      this.log('global', 'error', data.message, data.context, data.error);
    });
  }

  /**
   * 验证参数类型
   *
   * @private
   * @param {string} category - 日志类目
   * @param {string} level - 日志等级，可选值 warning，error
   * @param {string} message - 错误信息
   * @param {object} context - 日志发生时的用户相关的场景信息
   * @param {object} error - 错误对象
   */
  static validate(category, level, message, context, error) {
    if (!category) {
      throw new Error('category is required');
    }
    if (!level) {
      throw new Error('level is required');
    }
    if (!message) {
      throw new Error('message is required');
    }

    const levels = ['warning', 'error'];
    if (levels.indexOf(level) === -1) {
      throw new Error('The value of level must in "warning" and "error"');
    }
    if (context && !isObject(context)) {
      throw new Error('The context parameter should be a key-value object');
    }
    if (error && !(error instanceof Error)) {
      throw new Error('The error parameter should be a Error object');
    }
  }
}

export default Log;
