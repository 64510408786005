import Event from './Event';
/**
 * 注册转化相关事件
 */
class Oauth extends Event {
  /**
   * 创建注册转化相关事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();
    const { _instance } = Oauth;
    if (_instance) {
      return _instance;
    }
    Oauth._instance = this;
    return this;
  }

  /**
   * 记录成功授权地理位置信息事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {string} [properties.locationLongitude] - 地理位置经度
   * @param {string} [properties.locationLatitude] - 地理位置纬度
   * @param {function} [cb] - 回调函数
   */
  logLocationOauthInform(properties = {}, cb) {
    this.log('maievent-wechatapp-location-oauth-inform', properties, cb);
  }

  /**
   * 记录尝试授权微信小程序个人信息事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {function} [cb] - 回调函数
   */
  logOauthInform(properties = {}, cb) {
    this.log('maievent-wechatapp-oauth-inform', properties, cb);
  }

  /**
   * 记录成功授权微信小程序个人信息事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {string} [properties.channelName] - 小程序名称
   * @param {string} [properties.channelId] - 小程序ID
   * @param {string} [properties.channelType] - 渠道类型
   * @param {function} [cb] - 回调函数
   */
  logOauth(properties = {}, cb) {
    this.log('maievent-wechatapp-oauth', properties, cb);
  }

  /**
   * 记录取消授权微信小程序个人信息事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {function} [cb] - 回调函数
   */
  logOauthCancel(properties = {}, cb) {
    this.log('maievent-wechatapp-oauth-cancel', properties, cb);
  }

  /**
   * 记录尝试授权微信小程序手机号事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {function} [cb] - 回调函数
   */
  logPhoneOauthInform(properties = {}, cb) {
    this.log('maievent-wechatapp-phone-oauth-inform', properties, cb);
  }

  /**
   * 记录成功授权微信小程序手机号事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {function} [cb] - 回调函数
   */
  logPhoneOauth(properties = {}, cb) {
    this.log('maievent-wechatapp-phone-oauth', properties, cb);
  }

  /**
   * 记录取消授权微信小程序手机号事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - 页面路径，由 SDK 自动添加
   * @param {function} [cb] - 回调函数
   */
  logPhoneOauthCancel(properties = {}, cb) {
    this.log('maievent-wechatapp-phone-oauth-cancel', properties, cb);
  }
}

export default Oauth;
