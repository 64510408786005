/**
 * 枚举类
 */
class Enums {
  /**
   * 页面操作行为枚举类型
   *
   * @readonly
   * @enum {string}
   * @property {string} CLICK - 点击，点击优先用此 action
   * @property {string} TAP - 点击
   * @property {string} SWIPE - 滑动
   * @property {string} CONFIRM - 确认，提交优先用此 action
   * @property {string} SUBMIT - 提交
   * @property {string} CANCEL - 取消
   * @property {string} SEARCH - 搜索关键词
   * @property {string} VIEW - 查看
   * @property {string} CHANGE - 修改
   * @property {string} HELP - 助力
   * @property {string} SHARE - 分享
   * @property {string} ADD_TO_CART - 加入购物车
   * @property {string} CLICK_TAB - 点击 Tab
   * @property {string} REDIRECT - 跳转到其他链接
   * @property {string} CONTACT - 联系客服
   * @property {string} WANT_TO_BUY - 立即购买
   * @property {string} CHECKOUT - 结算
   * @property {string} SUBMIT_ORDER - 提交订单
   * @property {string} CHOOSE_PAYMENT - 选择支付方式
   * @property {string} PURCHASE_PRODUCT - 购买商品成功
   * @property {string} CANCEL_ORDER - 取消订单
   */
  static OperateActions = {
    CLICK: '点击',
    TAP: '点击',
    SWIPE: '滑动',
    CONFIRM: '确认',
    SUBMIT: '提交',
    CANCEL: '取消',
    SEARCH: '搜索关键词',
    VIEW: '查看',
    CHANGE: '修改',
    HELP: '助力',
    SHARE: '分享',
    ADD_TO_CART: '加入购物车',
    CLICK_TAB: '点击Tab',
    REDIRECT: '跳转到其他链接',
    CONTACT: '联系客服',
    WANT_TO_BUY: '立即购买',
    CHECKOUT: '结算',
    SUBMIT_ORDER: '提交订单',
    CHOOSE_PAYMENT: '选择支付方式',
    PURCHASE_PRODUCT: '购买商品成功',
    CANCEL_ORDER: '取消订单',
  };

  /**
   * 购物车行为枚举类型
   *
   * @enum {string}
   * @property {string} ADD - 加入购物车
   * @property {string} PLUS - 购物车加号
   * @property {string} MINUS - 购物车减号
   */
  static CartActions = {
    ADD: '加入购物车',
    PLUS: '购物车加号',
    MINUS: '购物车减号',
  };

  /**
   * 搜索关键词行为枚举类型
   *
   * @enum {string}
   * @property {string} SEARCH - 人工搜索
   * @property {string} HISTORY - 历史搜索
   * @property {string} HOT - 热门搜索
   */
   static SearchActions = {
     SEARCH: '人工搜索',
     HISTORY: '历史搜索',
     HOT: '热门搜索',
   }

  /**
   * 支付宝小程序授权 scope 枚举类型
   * @readonly
   * @enum {string}
   * @property {string} AUTH_BASE - 支付宝小程序隐式授权
   * @property {string} AUTH_USER - 支付宝小程序显示授权
   * @property {string} AUTH_ZHIMA - 支付宝小程序获取用户芝麻信息
   */
  static AlipayappAuthScope = {
    AUTH_BASE: 'auth_base',
    AUTH_USER: 'auth_user',
    AUTH_ZHIMA: 'auth_zhima',
  };
}

export default Enums;
