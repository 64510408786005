import qs from 'qs';
import { isWechatWeapp } from './lang';
import { toCamelCase } from './string';

const utmProperties = {};

const getUtmFields = (properties) => {
  const propertiesCopy = { ...properties };
  Object.keys(propertiesCopy).forEach((key) => {
    propertiesCopy[toCamelCase(key)] = propertiesCopy[key];
  });
  const utmFields = ['utmSource', 'utmContent', 'utmMedium', 'utmCampaign', 'utmTerm'];
  const utmProperties = {};
  utmFields.forEach((field) => {
    if (propertiesCopy[field]) {
      utmProperties[field] = propertiesCopy[field];
    }
  });
  if (propertiesCopy.gdt_vid) {
    utmProperties.utmContent = propertiesCopy.gdt_vid;
  }
  if (propertiesCopy.weixinadinfo) {
    utmProperties.utmTerm = propertiesCopy.weixinadinfo;
  }
  if (propertiesCopy.gdt_vid && propertiesCopy.utmCampaign) {
    utmProperties.utmSource = 'wechat_ad';
  }
  return utmProperties;
};

const getUtmFromUrl = () => {
  let query = {};
  if (isWechatWeapp) {
    // eslint-disable-next-line prefer-destructuring
    query = wx.getLaunchOptionsSync().query;
  } else {
    query = {
      ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
      ...qs.parse(window.location.hash.replace(/^#[^?]*\?/, '')),
    };
  }
  return getUtmFields(query);
};

const getUtmFromScene = () => {
  if (isWechatWeapp) {
    const { scene } = wx.getLaunchOptionsSync();
    return { utmSource: `mp_${scene}` };
  }
  return {};
};

Object.assign(utmProperties, getUtmFromScene(), getUtmFromUrl());

const getUtmProperties = () => utmProperties;

const setUtmProperties = (properties) => {
  Object.assign(utmProperties, getUtmFields(properties));
};

export {
  getUtmProperties,
  setUtmProperties,
};
