import Event from './Event';
/**
 * 活动运营事件
 */
class Campaign extends Event {
  /**
   * 创建活动运营事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();
    const { _instance } = Campaign;
    if (_instance) {
      return _instance;
    }
    Campaign._instance = this;
    return this;
  }

  /**
   * 记录浏览活动首页事件
   *
   * @deprecated since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} properties.content - 页面名称
   * @param {object} [properties.loginInfo] - 账号信息
   * @param {string} [properties.loginInfo.loginStatus] - 本次参与时状态，new 从未参与、rejoin 再次参与
   * @param {object} [properties.loginInfo.loginAccount] - 正常参与的账号，email、加密后手机、unionId
   * @param {object} [properties.userInfo] - 用户信息
   * @param {string} [properties.userInfo.followerStatus] - 关注状态，new 从未关注、unsubscribed 取关、subscribed 关注中
   * @param {string} [properties.userInfo.leadsStatus] - 会员状态，notLeads 非会员、isLeads 会员、newLeads 新会员、existingLeads 老会员
   * @param {string} [properties.userInfo.customerStatus] - 客户状态，newCustomer 新客、existingCustomer 老客、nonCustomer 非客户
   * @param {function} [cb] - 回调函数
   */
  logPageLanding(properties = {}, cb) {
    // eslint-disable-next-line no-console
    console.warn('The current api "Campagin.logPageLanding" has been deprecated and disabled, please remove it ASAP');
  }

  /**
   * 记录提交事件
   *
   * @deprecated since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} properties.content - 操作内容
   * @param {boolean} properties.hasUpdatedProfile - 是否留资
   * @param {function} [cb] - 回调函数
   */
  logPageSubmit(properties = {}, cb) {
    // eslint-disable-next-line no-console
    console.warn('The current api "Campagin.logPageSubmit" has been deprecated and disabled, please remove it ASAP');
  }

  /**
   * 记录跳转到其他链接事件
   *
   * @deprecated since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} properties.toUrl - 跳转 url 地址
   * @param {string} properties.content - 操作内容
   * @param {string} properties.submitStatus - 跳转前成功参加状态，success、fail、unfinished
   * @param {string} [properties.rewardStatus] - 跳转前奖励获取状态，redpack、gift、coupon、none
   * @param {function} [cb] - 回调函数
   */
  logPageRedirect(properties = {}, cb) {
    // eslint-disable-next-line no-console
    console.warn('The current api "Campagin.logPageRedirect" has been deprecated and disabled, please remove it ASAP');
  }

  /**
   * 记录活动首页浏览事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 页面名称
   * @param {string} [properties.url] - url
   * @param {string} [properties.refererUrl] - 上一页面路径
   * @param {string} [properties.durationInSeconds] - 停留时长(s)
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logHomepageView(properties = {}, cb) {
    this.log('maievent-homepage-view', properties, cb);
  }

  /**
   * 记录用户开始参与事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logCampaignStart(properties = {}, cb) {
    this.log('maievent-campaign-start', properties, cb);
  }

  /**
   * 记录用户活动留资事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logCampaignProfileFill(properties = {}, cb) {
    this.log('maievent-campaign-profile-fill', properties, cb);
  }

  /**
   * 记录用户完成参与事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {string} [properties.failedReason] - 失败原因
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logCampaignSubmit(properties = {}, cb) {
    this.log('maievent-campaign-submit', properties, cb);
  }

  /**
   * 记录用户开始领奖事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {string} [properties.prize] - 奖励信息
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logPrizeStart(properties = {}, cb) {
    this.log('maievent-prize-start', properties, cb);
  }

  /**
   * 记录用户领奖信息提交事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {string} [properties.infoType] - 信息类型
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logPrizeInfoSubmit(properties = {}, cb) {
    this.log('maievent-prize-info-submit', properties, cb);
  }

  /**
   * 记录用户点击抽奖事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logLuckydrawStart(properties = {}, cb) {
    this.log('maievent-luckydraw-start', properties, cb);
  }

  /**
   * 记录用户抽奖完成事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.content] - 操作内容
   * @param {string} [properties.result] - 抽奖结果
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logLuckydrawSubmit(properties = {}, cb) {
    this.log('maievent-luckydraw-submit', properties, cb);
  }

  /**
   * 记录用户分享活动事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} [properties.pageContent] - 分享时页面
   * @param {string} [properties.url] - 分享活动所在页面的 URL
   * @param {string} [properties.shareTo] - 分享类型
   * @param {boolean} [properties.isFirstTime] - 是否首次参与
   * @param {boolean} [properties.isMember] - 参与时是否会员
   * @param {boolean} [properties.isFollowingFans] - 参与时是否关注
   * @param {boolean} [properties.isCustomer] - 参与时是否有过购买
   * @param {number} [properties.depth] - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logCampaignShare(properties = {}, cb) {
    this.log('maievent-campaign-share', properties, cb);
  }
}

export default Campaign;
