const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

const isFunction = (value) => Object.prototype.toString.call(value) === '[object Function]';

// taro 会注入 window 和 document 对象
// uni-app 会为不同平台尽可能提供相同 wx 接口，[https://uniapp.dcloud.io/api/other/open-miniprogram?id=navigatetominiprogram](不支持 H5)
const isWechatWeapp = !!(typeof wx === 'object' && wx.navigateToMiniProgram);

const isAlipayapp = typeof window === 'undefined' && typeof document === 'undefined' && typeof my !== 'undefined';

const isBytedanceapp = typeof window === 'undefined' && typeof document === 'undefined' && typeof tt !== 'undefined';

const isQyWeapp = isWechatWeapp && wx.getSystemInfoSync().environment === 'wxwork';

const isQyH5 = () => {
  return !!(navigator && /wxwork/i.test(navigator.userAgent));
};

export {
  isObject,
  isFunction,
  isWechatWeapp,
  isQyH5,
  isQyWeapp,
  isAlipayapp,
  isBytedanceapp,
};
