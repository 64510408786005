import UAParser from 'ua-parser-js';
import qs from 'qs';
import { isWechatWeapp } from './lang';

const urlContentMap = {};

const urlPropertiesWillBeIgnored = [
  'redirectUrl',
  'RedirectUrl',
  'redirect-url',
];

const platform = {
  sdk: 'js', // SDK 类型
  sdkVersion: process.env.SDK_VERSION, // maijs 版本
};

if (isWechatWeapp) {
  const res = wx.getSystemInfoSync();
  const [os, osVersion] = res.system.split(' ');
  Object.assign(platform, {
    screenWidth: res.screenWidth,
    screenHeight: res.screenHeight,
    manufacturer: res.brand,
    model: res.model,
    os,
    osVersion,
  });
} else {
  const { userAgent } = window.navigator;
  const { device, os, browser } = UAParser(userAgent);
  Object.assign(platform, {
    userAgent, // 用户代理字符串
    screenWidth: window.screen.width, // 屏幕宽度
    screenHeight: window.screen.height, // 屏幕高度
    manufacturer: device.vendor, // 设备制造商
    model: device.model, // 设备型号
    os: os.name, // 操作系统
    osVersion: os.version, // 操作系统版本
    browser: browser.name, // 浏览器
    browserVersion: browser.version, // 浏览器版本
  });
}

const getPath = (url) => {
  const idx = url.indexOf('?');
  return idx === -1 ? url : url.slice(0, idx);
};

const setPageContent = (url, content) => {
  urlContentMap[getPath(url)] = content;
};

const getPageContent = (url) => {
  return urlContentMap[getPath(url)];
};

const getUrl = (path, options) => {
  const newOptions = {};
  for (const key in options) {
    if (urlPropertiesWillBeIgnored.includes(key)) {
      continue;
    }
    if (typeof options[key] === 'object') {
      continue;
    }
    newOptions[key] = options[key];
  }
  const queryStr = qs.stringify(newOptions);
  const connector = queryStr ? '?' : '';
  return `${path}${connector}${queryStr}`;
};

const getPlatform = () => {
  if (isWechatWeapp) {
    const pages = getCurrentPages();
    let url = '';
    if (pages.length) {
      const currentPage = pages[pages.length - 1];
      url = getUrl(currentPage.route, currentPage.options);
    } else {
      const launchOptions = wx.getLaunchOptionsSync();
      url = getUrl(launchOptions.path, launchOptions.query);
    }
    platform.url = url;
    platform.content = getPageContent(url);
  } else {
    platform.url = window.location.href;
    platform.content = document.title;
  }
  return platform;
};

export {
  getPlatform,
  setPageContent,
};
