import { request } from './utils/request';

/**
 * 封装 Puppeteer API 类
 */
class Puppeteer {
  /**
   * 通过 HTML 创建活动海报
   *
   * @param {object} options - 生成海报所需参数
   * @param {object} options.html - 生成本地 HTML 所需参数
   * @param {'vue'} [options.html.type] - 模板类型，目前只支持 Vue.js
   * @param {string} options.html.template - 模板内容
   * @param {object} options.html.data - 模板数据
   * @param {object} options.oss - oss 缓存参数
   * @param {string} options.oss.module - 该海报所在模块名称
   * @param {string} options.oss.businessName - 该海报所对应业务名称
   * @param {'r7d'|'r30d'|'r90d'|'r180d'|'r365d'} [options.oss.retentionPolicy] - 保存天数，如果为空则永久保存
   * @param {puppeteer.Viewport} [options.viewport] - 参考 [page.setViewport(viewport)](https://github.com/GoogleChrome/puppeteer/blob/v1.17.0/docs/api.md#pagesetviewportviewport)
   * @param {puppeteer.ScreenshotOptions} [options.screenshot] - 参考 [page.screenshot([options])](https://github.com/GoogleChrome/puppeteer/blob/v1.17.0/docs/api.md#pagescreenshotoptions)
   * - 注意：path 参数会被忽略
   * @returns {Promise<object>}
   */
  buildPoster(options) {
    return request.post('/v2/puppeteer/html2image', options);
  }
}

export default Puppeteer;
