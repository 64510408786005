import { isWechatWeapp } from './lang';

const localStorage = {
  setItem: null,
  getItem: null,
  removeItem: null,
};

if (isWechatWeapp) {
  localStorage.setItem = (key, value) => {
    wx.setStorageSync(key, value);
  };

  localStorage.getItem = (key) => wx.getStorageSync(key);

  localStorage.removeItem = (key) => {
    wx.removeStorageSync(key);
  };
} else {
  localStorage.setItem = (key, value) => {
    const jsonStr = JSON.stringify(value);
    window.localStorage.setItem(key, jsonStr);
  };

  localStorage.getItem = (key) => {
    const jsonStr = window.localStorage.getItem(key);
    return JSON.parse(jsonStr);
  };

  localStorage.removeItem = (key) => {
    window.localStorage.removeItem(key);
  };
}

export default localStorage;
