import { isWechatWeapp, isQyWeapp, isBytedanceapp, isAlipayapp } from './utils/lang';
import promisify from './utils/promisify';

// 微信小程序原生 API 集合
const weappApis = [
  'canvasToTempFilePath',
  'showToast',
  'showModal',
  'showActionSheet',
  'stopPullDownRefresh',
  'setNavigationBarTitle',
  'setNavigationBarColor',
  'setTopBarText',
  'switchTab',
  'navigateTo',
  'navigateBack',
  'redirectTo',
  'login',
  'checkSession',
  'authorize',
  'getUserInfo',
  'getUserProfile',
  'requestPayment',
  'chooseImage',
  'previewImage',
  'getImageInfo',
  'saveImageToPhotosAlbum',
  'uploadFile',
  'downloadFile',
  'startRecord',
  'playVoice',
  'getBackgroundAudioPlayerState',
  'playBackgroundAudio',
  'seekBackgroundAudio',
  'chooseVideo',
  'saveVideoToPhotosAlbum',
  'saveFile',
  'getFileInfo',
  'getSavedFileList',
  'getSavedFileInfo',
  'removeSavedFile',
  'openDocument',
  'setStorage',
  'getStorage',
  'getStorageInfo',
  'removeStorage',
  'getLocation',
  'chooseLocation',
  'openLocation',
  'getSystemInfo',
  'getNetworkType',
  'startAccelerometer',
  'stopAccelerometer',
  'startCompass',
  'stopCompass',
  'makePhoneCall',
  'scanCode',
  'setClipboardData',
  'getClipboardData',
  'openBluetoothAdapter',
  'closeBluetoothAdapter',
  'getBluetoothAdapterState',
  'startBluetoothDevicesDiscovery',
  'stopBluetoothDevicesDiscovery',
  'getBluetoothDevices',
  'getConnectedBluetoothDevices',
  'createBLEConnection',
  'closeBLEConnection',
  'getBLEDeviceServices',
  'startBeaconDiscovery',
  'stopBeaconDiscovery',
  'getBeacons',
  'setScreenBrightness',
  'getScreenBrightness',
  'setKeepScreenOn',
  'vibrateLong',
  'vibrateShort',
  'addPhoneContact',
  'getHCEState',
  'startHCE',
  'stopHCE',
  'sendHCEMessage',
  'startWifi',
  'stopWifi',
  'connectWifi',
  'getWifiList',
  'setWifiList',
  'getConnectedWifi',
  'getExtConfig',
  'showShareMenu',
  'hideShareMenu',
  'updateShareMenu',
  'getShareInfo',
  'chooseAddress',
  'addCard',
  'openCard',
  'openSetting',
  'getSetting',
  'getWeRunData',
  'navigateToMiniProgram',
  'navigateBackMiniProgram',
  'chooseInvoiceTitle',
  'checkIsSupportSoterAuthentication',
  'startSoterAuthentication',
  'checkIsSoterEnrolledInDevice',
];

const qyWeappApis = [
  'login',
  'checkSession',
  'canIUse',
  'getEnterpriseUserInfo',
  'getAvatar',
  'getQrCode',
  'selectEnterpriseContact',
  'selectExternalContact',
  'openUserProfile',
  'openEnterpriseChat',
  'getCurExternalContact',
  'shareToExternalContact',
  'getCurExternalChat',
  'sendChatMessage',
  'shareToExternalChat',
];

const bytedanceappApis = [
  'getUserInfo',
];

const alipayappApis = [
  'getAuthCode',
  'getAppIdSync',
];

/**
 * （企业）微信小程序 JSSDK 封装类，使用 API 支持 Promise。
 */
class WxWeapp {
  qy = {};

  /**
   * 创建 WxWeapp 实例
   */
  constructor() {
    // 判断是否是微信小程序
    if (isWechatWeapp) {
      weappApis.forEach((api) => {
        this[api] = (...args) => promisify(wx[api], ...args);
      });
    }
    if (isQyWeapp) {
      qyWeappApis.forEach((api) => {
        this.qy[api] = (...args) => promisify(wx.qy[api], ...args);
      });
    }
    if (isBytedanceapp) {
      bytedanceappApis.forEach((api) => {
        this[api] = (...args) => promisify(tt[api], ...args);
      });
      this.getUserProfile = this.getUserInfo;
    }
    if (isAlipayapp) {
      alipayappApis.forEach((api) => {
        this[api] = (...args) => promisify(my[api], ...args);
      });
    }
  }
}

export default new WxWeapp();
