import Event from './Event';
import { isWechatWeapp, isBytedanceapp, isAlipayapp } from '../utils/lang';

/**
 * 流量运营事件
 *
 * @extends Event
 */
class Traffic extends Event {
  /**
   * 创建流量运营事件实例(单例)
   */
  constructor() {
    super();
    const { _instance } = Traffic;
    if (_instance) {
      return _instance;
    }
    Traffic._instance = this;
    return this;
  }

  /**
   * 记录应用启动事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.scene]  - 可选，小程序场景值 ID，比如 1001。如果不传，SDK 自动添加
   * @param {string} [properties.appId]  - 可选，小程序 appId。如果不传，SDK 自动添加
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {string} [properties.queryScene] - 可选，小程序码 scene
   * @param {function} [cb] - 回调函数
   */
  logAppLaunch(properties = {}, cb) {
    if (isWechatWeapp && !isAlipayapp && !isBytedanceapp) {
      if (!properties.appId) {
        const { miniProgram } = wx.getAccountInfoSync();
        properties.appId = miniProgram.appId;
      }
      const { scene } = wx.getLaunchOptionsSync();
      if (!properties.scene) {
        properties.scene = scene;
      }
    } else if (isBytedanceapp) {
      const { scene } = tt.getLaunchOptionsSync();
      if (!properties.scene) {
        properties.scene = scene;
      }
    }
    if (!properties.scene || !properties.appId) {
      throw new Error('scene and appId are required');
    }
    this.log('maievent-app-launch', properties, cb);
  }

  /**
   * 记录小程序显示事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.scene]  - 可选，小程序场景值 ID。如果不传，SDK 自动添加
   * @param {string} [properties.appId]  - 可选，小程序 appId。如果不传，SDK 自动添加
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {string} [properties.queryScene]  - 可选，小程序码 scene
   * @param {function} [cb] - 回调函数
   */
  logAppShow(properties = {}, cb) {
    this.log('maievent-app-show', properties, cb);
  }

  /**
   * 记录小程序隐藏事件
   *
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {function} [cb] - 回调函数
   */
  logAppExit(properties = {}, cb) {
    this.log('maievent-app-exit', properties, cb);
  }

  /**
   * 记录分享页面事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.pageContent] - 页面名称
   * @param {string} [properties.url] - 页面路径
   * @param {string} [properties.fromType] - 转发事件来源
   * @param {string} [properties.shareTo] - 分享类型
   * @param {string} [properties.sharePath] - 转发页面路径，不填默认是当前页面。通过路径解析分享的entity
   * @param {string} [properties.shareTitle] - 转发标题
   * @param {string} [properties.shareImageUrl] - 转发图片路径
   * @param {function} [cb] - 回调函数
   */
  logPageShare(properties = {}, cb) {
    this.log('maievent-page-share', properties, cb);
  }

  /**
   * 记录页面离开事件
   *
   * @since 1.35.0
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.url] - 可选，启动页面路径。如果不传，SDK 自动添加
   * @param {string} [properties.content] - 可选，启动页面标题。如果不传，SDK 自动添加
   * @param {string} [properties.durationInSeconds] - 停留时长(s)
   * @param {function} [cb] - 回调函数
   */
  logPageLeave(properties = {}, cb) {
    this.log('maievent-page-leave', properties, cb);
  }
}

export default Traffic;
