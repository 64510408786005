import { getUtmProperties, setUtmProperties } from './utils/utm';
import Event from './memberEvents/Event';
import { Campaign, Traffic, Feature, Trade, Page, Oauth, Score, Share } from './memberEvents';
/**
 * 客户事件类
 *
 * @extends Event
 */
class MemberEvent extends Event {
  /**
   * @type {Campaign}
   */
  campaign = null;

  /**
   * @type {Traffic}
   */
  traffic = null;

  /**
   * @type {Feature}
   */
  feature = null;

  /**
   * @type {Trade}
   */
  trade = null;

  /**
   * @type {Page}
   */
  page = null;

  /**
   * @type {Oauth}
   */
  oauth = null;

  /**
   * @type {Score}
   */
  score = null;

  /**
   * @type {Share}
   */
  share = null;

  /**
   * 创建 MemberEvent 实例
   */
   constructor() {
     super();

     this.campaign = new Campaign();
     this.traffic = new Traffic();
     this.feature = new Feature();
     this.trade = new Trade();
     this.page = new Page();
     this.oauth = new Oauth();
     this.score = new Score();
     this.share = new Share();
   }

   /**
   * 上报 Page View 事件
   *
   * @deprecated 请使用 mai.memberEvent.page.logView() 代替
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.utmCampaign] - 可选，访问来源所关联的活动
   * @param {string} [properties.utmContent] - 可选，访问来源内容
   * @param {string} [properties.utmTerm] - 可选，访问来源搜索关键词
   * @param {string} [properties.utmMedium] - 可选，访问来源链接类型
   * @param {string} [properties.utmSource] - 可选，访问来源
   * @param {string} [properties.content] - 可选，当前页面内容，由 SDK 自动添加（取页面标题）
   * @param {string} [properties.url] - 可选，当前页面地址，由 SDK 自动添加
   * @param {string} [properties.refererContent] - 可选，前一页内容，由 SDK 自动添加（上一次记录的）
   * @param {string} [properties.refererUrl] - 可选，要跳转到的页面内容
   * @param {string} [properties.redirectContent] - 可选，要跳转到的页面地址
   * @param {string} [properties.redirectUrl] - 可选，要跳转到的页面地址
   * @param {object} [properties.extra] - 可选，额外信息
   * @param {function} [cb] - 回调函数
  */
   logPageView(properties = {}, cb) {
     // eslint-disable-next-line no-console
     console.warn('【Deprecated】 "mai.memberEvent.logPageView()" has been deprecated, please update to "mai.memberEvent.page.logView()" ASAP.');
     return this.page.logView(properties, cb);
   }

   /**
   * 上报 Page Operate 事件
   *
   * @deprecated 请使用 mai.memberEvent.page.logOperate() 代替
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.utmSource] - 可选，访问来源
   * @param {string} [properties.utmContent] - 可选，访问来源内容
   * @param {string} [properties.utmMedium] - 可选，访问来源链接类型
   * @param {string} [properties.utmCampaign] - 可选，访问来源所关联的活动
   * @param {string} [properties.utmTerm] - 可选，访问来源搜索关键词
   * @param {string} [properties.pageContent] - 可选，当前页面内容，由 SDK 自动添加（取页面标题）
   * @param {string} [properties.url] - 可选，当前页面地址，由 SDK 自动添加
   * @param {string} [properties.type] - 操作的类型，可选值：click、tap、swipe、scroll、choose、input 或自定义
   * @param {string} properties.action - 操作类型
   * @param {string} properties.content - 操作的内容
   * @param {string} [properties.targetId] - 可选，元素唯一编号
   * @param {string} [properties.redirectContent] - 可选，操作后要跳转到的页面内容
   * @param {string} [properties.redirectUrl] - 可选，操作后要跳转到的页面地址
   * @param {object} [properties.extra] - 可选，额外信息
   * @param {function} [cb] - 回调函数
   */
   logPageOperate(properties = {}, cb) {
     // eslint-disable-next-line no-console
     console.warn('【Deprecated】 "mai.memberEvent.logPageOperate()" has been deprecated, please update to "mai.memberEvent.page.logOperate()" ASAP.');
     return this.page.logOperate(properties, cb);
   }

   /**
   * 获取默认 UTM 参数
   *
   * @returns {object} UTM 参数对象
   */
   getUtmProperties() {
     return { ...getUtmProperties() };
   }

   /**
   * 设置 UTM 参数
   *
   * @param {object} [properties={}] - 事件属性，字段支持驼峰和下划线分隔的格式。如 utmSource 和 utm_source
   * @param {string} [properties.utmSource] - 可选，访问来源
   * @param {string} [properties.utmContent] - 可选，访问来源内容
   * @param {string} [properties.utmMedium] - 可选，访问来源链接类型
   * @param {string} [properties.utmCampaign] - 可选，访问来源所关联的活动
   * @param {string} [properties.utmTerm] - 可选，访问来源搜索关键词
   */
   setUtmProperties(properties = {}) {
     setUtmProperties(properties);
   }
}

export default MemberEvent;
