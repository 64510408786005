import Mai from '../Mai';
import Event from './Event';

/**
 * 分享相关事件
 */
class Share extends Event {
  /**
   * 创建分享相关事件实例(单例)
   *
   * @extends Event
   */
  constructor() {
    super();
    const { _instance } = Share;
    if (_instance) {
      return _instance;
    }
    Share._instance = this;
    return this;
  }

  /**
   * 记录用户分享活动事件
   *
   * @deprecated since 1.35.0，请使用 mai.memberEvent.campaign.logCampaignShare() 代替
   * @param {object} [properties={}] - 事件属性
   * @param {string} [properties.campaignId] - 活动 ID
   * @param {string} [properties.campaignName] - 活动名称
   * @param {string} properties.pageContent - 分享时页面
   * @param {string} properties.url - 分享活动所在页面的 URL
   * @param {string} properties.shareTo - 分享类型
   * @param {boolean} properties.isFirstTime - 是否首次参与
   * @param {boolean} properties.isMember - 参与时是否会员
   * @param {boolean} properties.isFollowingFans - 参与时是否关注
   * @param {boolean} properties.isCustomer - 参与时是否有过购买
   * @param {number} properties.depth - 参与时裂变级数
   * @param {function} [cb] - 回调函数
   */
  logCampaignShare(properties = {}, cb) {
    // eslint-disable-next-line no-console
    console.warn('The current api "Share.logCampaignShare" has been deprecated, please replace by "Campaign.logCampaignShare" ASAP');
    Mai._instance.memberEvent.campaign.logCampaignShare(properties, cb);
  }

  /**
   * 记录分享页面事件
   *
   * @deprecated since 1.35.0，请使用 mai.memberEvent.traffic.logPageShare() 代替
   * @param {object} [properties={}] - 事件属性
   * @param {string} properties.pageContent - 页面名称
   * @param {string} properties.url - 页面路径
   * @param {string} properties.fromType - 转发事件来源
   * @param {string} properties.shareTo - 分享类型
   * @param {string} properties.sharePath - 转发页面路径，不填默认是当前页面。通过路径解析分享的entity
   * @param {string} properties.shareTitle - 转发标题
   * @param {string} properties.shareImageUrl - 转发图片路径
   * @param {function} [cb] - 回调函数
   */
  logPageShare(properties = {}, cb) {
    // eslint-disable-next-line no-console
    console.warn('The current api "Share.logPageShare" has been deprecated, please replace by "Traffic.logPageShare" ASAP');
    Mai._instance.memberEvent.traffic.logPageShare(properties, cb);
  }
}

export default Share;
