import debounce from './debounce';
import { isWechatWeapp, isFunction } from './lang';

/**
 * @private
 * 防抖缓存类
 */
class DebounceBuffer {
  /**
   * 根据防抖回调函数和延时创建防抖缓存实例
   *
   * @param {function} onFlush - 防抖回调函数
   * @param {number} [delay=300] - 防抖延时时间
   */
  constructor(onFlush, delay = 300) {
    this._buffer = [];
    this._callbacks = [];
    this._delay = delay;
    this._debounce = null;
    this._onFlush = onFlush;

    if (!isWechatWeapp) {
      // 监听浏览器的刷新或者关闭事件
      // IE 浏览器中，当 beforeunload 的回调返回不为 null 或者 undefined 时，会在离开页面时弹出确认框
      window.addEventListener('beforeunload', () => {
        this.onFlush();
      });
      // 监听微信浏览器的关闭事件
      window.addEventListener('visibilitychange', this.onFlush.bind(this));
    } else if (wx.onAppHide) {
      // 监听微信小程序切后台事件
      wx.onAppHide(this.onFlush.bind(this));
    }
  }

  /**
   * 防抖函数
   *
   * @private
   * @param {object} data - 需要缓存的数据
   */
  debounce(data, cb) {
    this._buffer.push(data);
    if (isFunction(cb)) {
      this._callbacks.push(cb);
    }
    if (!this._debounce) {
      this._debounce = debounce(this.onFlush, this._delay);
    }
    this._debounce();
  }

  /**
   * 清空防抖缓存中的数据
   * @private
   */
  async onFlush() {
    if (this._buffer.length === 0) {
      return;
    }
    let buffer = [];
    let callbacks = [];
    try {
      buffer = this._buffer;
      callbacks = this._callbacks;
      this._buffer = [];
      this._callbacks = [];
      await this._onFlush(buffer);
    } catch (error) {
      this._buffer.push(...buffer);
    } finally {
      callbacks.forEach((cb) => cb());
    }
  }
}

export default DebounceBuffer;
