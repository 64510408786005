const uuid = () => {
  const s4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return (`${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`);
};

const toCamelCase = (str, separator = '_') => {
  const regx = new RegExp(`${separator}[a-z]`, 'g');
  return str.replace(regx, (match) => {
    return match[1].toUpperCase();
  });
};

export {
  uuid,
  toCamelCase,
};
